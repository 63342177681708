import React from 'react';
import compose from 'lodash/fp/compose';
import { withCookies } from '@catalogo/core-connect/cookies';

const now = new Date();
const expireDate = now.setDate(now.getDate() + 7);

export const createCloseCookie = setCookie => () => {
  const date = new Date(expireDate);
  const expires = date.toGMTString();

  setCookie('smtbClose', 1, { expires });
};

export const withSmartBanner = Component => props => {
  const { setCookie, cookies } = props;

  if (cookies.smtbClose) {
    return null;
  }

  return <Component {...props} onCloseHook={createCloseCookie(setCookie)} />;
};

export default compose(withCookies, withSmartBanner);
