import React from 'react';
import styled, { css } from 'styled-components';
import { Nav } from '@catalogo/core-nav';
import Image from '@catalogo/ui-americanas-desktop-image';

export const ZionSelectShowcaseLink = ({ publication }) => {
  const { linkName, linkUrl, linkImg, altTextLink } = publication;
  return (
    <Link to={linkUrl}>
      <Image url={linkImg} alt={altTextLink} />
      <Label>{linkName}</Label>
    </Link>
  );
};

export default ZionSelectShowcaseLink;
const Link = styled(Nav)`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
const Label = styled.span`
  ${({ theme }) => css`
    text-overflow: ellipsis;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    font-size: ${theme.fontSize.xs};
    color: ${theme.grey.dark};
    line-height: 1.4;
    margin-top: 4px;
    text-align: center;
  `}
`;
