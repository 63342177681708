import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { getIn } from 'seamless-immutable';
import { Nav } from '@catalogo/core-nav';
import Close from '@catalogo/ui-americanas-desktop-svg/close.svg';
import { LazyPicture } from '@catalogo/theme-lazy-picture';
import PropTypes from 'prop-types';
import compose from 'lodash/fp/compose';
import { withMetrics } from '@catalogo/core-metrics';
import withSmartBanner from '@catalogo/service-americanas-spacey/src/hocs/smart-banner';

export const ZionSmartBanner = props => {
  const { onCloseHook, dispatchMetrics } = props;
  const publicationData = getIn(props, ['publication']);
  const { iconUrl, bgColor, textColor, bannerTitle, btnColor, closeColor, bannerUrl } = publicationData;
  const title = bannerTitle.length > 47 ? `${bannerTitle.slice(0, 47)}...` : bannerTitle;
  const [visible, setVisible] = useState(true);

  const closeModal = () => {
    setVisible(false);
    onCloseHook();
    dispatchMetrics('event:track', { action: 'smart-banner-x' });
  };

  return (
    <Animation type="slide" direction="top" visible={visible} background={true}>
      <Wrapper bgColor={bgColor}>
        <NavUI onClick={closeModal} feedback={false}>
          <Close image="icon-close" height="20" width="20" fill={closeColor} alt="ícone para fechar o banner" />
        </NavUI>
        <Icon>
          <LazyPicture sources={{ mobile: { src: iconUrl }, ratio: '1:1' }} alt="ícone da americanas" />
        </Icon>
        <Message textColor={textColor}>
          <p>{title}</p>
        </Message>
        <Button
          to={bannerUrl}
          btnColor={btnColor}
          onClick={() => dispatchMetrics('event:track', { action: 'smart-banner-baixar-app' })}
        >
          baixar o app
        </Button>
      </Wrapper>
    </Animation>
  );
};

ZionSmartBanner.defaultProps = {
  onCloseHook: f => f,
};

ZionSmartBanner.propTypes = {
  publication: PropTypes.object,
};

export default compose(withMetrics, withSmartBanner)(ZionSmartBanner);

const Animation = styled.div`
  overflow-y: auto;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  height: 96px;
  transition: transform 0.45s cubic-bezier(0, 0, 0.3, 1);
  will-change: transform;
  transform: translate3d(0, 100%, 0);

  ${({ visible }) => visible && 'transform: translate3d(0, 0, 0);'}
`;

const Wrapper = styled.div`
  position: fixed;
  padding: 19px 10px;
  bottom: 0;
  z-index: 1;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme, bgColor }) => (bgColor ? bgColor : theme.white)};
`;

const NavUI = styled(Nav)`
  padding-right: 8px;
  height: 20px;
`;

const Icon = styled.div`
  max-height: 40px;
  max-width: 40px;
  flex: 1 0 auto;
`;

const Message = styled.div`
  align-items: flex-start;
  font-size: 13px;
  color: ${({ theme, textColor }) => (textColor ? textColor : theme.grey.dark)};
  margin: 0 8px;
  width: 160px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

const Button = styled(Nav)`
  display: flex;
  text-transform: lowercase;
  border-radius: 3px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  padding: 8px 9px;
  justify-content: center;
  width: 100%;
  max-width: 100px;
  ${({ theme, btnColor }) => css`
    border: 2px solid ${btnColor ? btnColor : theme.primaryColor};
    color: ${btnColor ? btnColor : theme.primaryColor};
  `}
`;
