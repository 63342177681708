import React from 'react';
import styled from 'styled-components';
import { LazyPicture } from '@catalogo/theme-lazy-picture';
import { Nav } from '@catalogo/core-nav';

const renderCardContent = (component, componentLink, pictureLink) => (
  <Cell>
    <Link to={component[componentLink]}>
      <LazyPicture lazy alt={component.title} sources={{ desktop: { src: component[pictureLink], ratio: '1:1' } }} />
    </Link>
  </Cell>
);

const PartnerStoresCard = ({ component }) => {
  if (!component) {
    return null;
  }

  return (
    <PartnerCard>
      <WrapperPicture>
        <LazyPicture
          lazy
          alt={component.title}
          sources={{ desktop: { src: component['background-image'], ratio: '1:1' } }}
        />
      </WrapperPicture>
      <WrapperItens>
        <WrapperImages>
          {renderCardContent(component, 'prd1-img-link', 'prd1-img')}
          {renderCardContent(component, 'prd2-img-link', 'prd2-img')}
          {renderCardContent(component, 'prd3-img-link', 'prd3-img')}
        </WrapperImages>
        <WrapperButton>
          <Button to={component['btn-link']}>{component['btn-text']}</Button>
        </WrapperButton>
      </WrapperItens>
    </PartnerCard>
  );
};

const PartnerCard = styled.div`
  width: 300px;
  height: 300px;
  margin: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 5px;
`;

const WrapperPicture = styled.div`
  width: 100%;
  height: 100%;
`;

const WrapperItens = styled.div`
  position: absolute;
  padding: 0 16px;
  left: 0;
  right: 0;
  bottom: 6px;
`;

const WrapperImages = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Cell = styled.div`
  display: flex;
  width: 22vw;
  border-radius: 4px;
  cursor: pointer;
`;

const Link = styled(Nav)`
  width: 100%;
  background: ${({ theme }) => theme.white};
  padding: 10px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.grey.light};
`;

const WrapperButton = styled.div`
  padding: 10px 0;
`;

const Button = styled(Nav)`
  display: block;
  width: 100%;
  background: ${({ theme }) => theme.white};
  padding: 13px 55px;
  text-align: center;
  color: ${({ theme }) => theme.primaryColor};
  border: 2px solid ${({ theme }) => theme.primaryColor};
  border-radius: 4px;
`;

export default PartnerStoresCard;
