import React from 'react';
import styled from 'styled-components';
import { Carousel } from '@catalogo/theme-carousel';
import { getIn } from 'seamless-immutable';
import PartnerStoresCard from '@catalogo/ui-americanas-mobile-partner-stores-card';

const PartnerStores = ({ publication }) => {
  const renderTitle = publication => {
    const title = getIn(publication, ['title-partners']);
    return title && <Title>{title}</Title>;
  };

  const renderPartnerStoreCard = publication => {
    if (!publication.children) {
      return null;
    }
    return (
      <Carousel>
        {publication.children.map(component => (
          <PartnerStoresCard component={component} key={component._id} />
        ))}
      </Carousel>
    );
  };

  return (
    <>
      {renderTitle(publication)}
      {renderPartnerStoreCard(publication)}
    </>
  );
};

const Title = styled.h2`
  color: ${({ theme }) => theme.fontColor || theme.titleColor};
  font-size: ${({ theme }) => theme.titleFont};
  font-weight: 700;
  margin: 20px 0 10px 10px;
`;

export default PartnerStores;
