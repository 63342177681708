import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const Carousel = ({ children, gap }) => (
  <Wrapper>
    <CollectionUI>
      <WrapperItems>
        {React.Children.map(children, (child, index) => (
          <Snap gap={index === children.length - 1 ? 0 : gap}>{child}</Snap>
        ))}
      </WrapperItems>
    </CollectionUI>
  </Wrapper>
);

const View = styled.div`
  display: flex;
  position: relative;
`;

const Wrapper = styled(View)`
  flex-direction: column;
`;

const Snap = styled(View)`
  scroll-snap-align: start;
  margin-right: ${props => `${props.gap}px`};
`;

const WrapperItems = styled(View)`
  flex-direction: row;
  float: left;
`;

const CollectionUI = styled(View)`
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  display: flex;
  flex-direction: row;
  -webkit-appearance: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

Carousel.propTypes = {
  children: PropTypes.array,
  gap: PropTypes.number,
};
