import React from 'react';
import styled from 'styled-components';
import { LazyPicture } from '@catalogo/theme-lazy-picture';
import { Nav } from '@catalogo/core-nav';

const ZionNavigationTabsCover = ({ publication }) => {
  const {
    navigationTabsCoverTitle,
    navigationTabsCoverImageText,
    navigationTabsCoverImageLink,
    navigationTabsCoverImage,
  } = publication;

  const sources = {
    mobile: {
      src: navigationTabsCoverImage || '',
      ratio: '4:3',
      width: publication?.meta?.navigationTabsCoverImage?.width,
      height: publication?.meta?.navigationTabsCoverImage?.height,
    },
  };

  return (
    <Container>
      <Title>{navigationTabsCoverTitle}</Title>
      <Nav to={navigationTabsCoverImageLink}>
        <LazyPicture sources={sources} alt={navigationTabsCoverImageText} />
      </Nav>
    </Container>
  );
};

export default ZionNavigationTabsCover;

const Container = styled.div`
  padding: 30px 15px;
`;

const Title = styled.h3`
  font-weight: bold;
  font-size: 16px;
  text-transform: lowercase;
  line-height: 1.4;
  padding: 0 0 20px;
  color: ${({ theme }) => theme.grey.dark};
`;
