import React from 'react';
import styled, { css } from 'styled-components';
import { LazyPicture } from '@catalogo/theme-lazy-picture';

export const Image = ({ url, alt, width, ratio = '1:1' }) => {
  const sources = {
    desktop: {
      src: url,
      ratio,
    },
  };
  return (
    <Container width={width}>
      <LazyPicture sources={sources} alt={alt} />
    </Container>
  );
};

export default Image;
const Container = styled.div`
  ${({ width }) => css`
    max-width: 100%;
    min-width: ${width ? width : '55px'};
  `}
`;
